<template>
  <div class="card">
    <div class="header">
      <a-icon type="arrow-left" class="icon" @click="back" />
      <span class="title">模型预览</span>
    </div>

    <ModelRender :url="url" />

    <div class="left-panel">
      <div class="title">
        <span class="icon"></span>
        <span>菜单</span>
      </div>
      <div class="list">
        <div
          class="item"
          v-for="item in fileList"
          :key="item"
          @click="open(item)"
        >
          <span> {{ decodeURI(getFileName(item)) }}</span>
          <a-icon type="right" class="icon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModelRender from "./components/model-render.vue";
export default {
  components: {
    ModelRender,
  },

  data() {
    return {
      fileList: [],
      url: "",
    };
  },

  mounted() {
    const file = JSON.parse(sessionStorage.getItem('fileUrls'));
    if (file) {
      let list = file.split(",");
      console.log(list)
      this.fileList = list.filter((item) => item.indexOf(".pdf") > -1);
      const url = list.find((item) => item.indexOf(".gltf") > -1);
      this.url = url ? decodeURIComponent(url) : "";
    }
  },

  methods: {
    back() {
      this.$router.go(-1);
    },

    getFileName(path) {
      if (typeof path === "string") {
        const index = path.indexOf("shet_");
        if (index > -1) {
          return path.substring(index + 5);
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    open(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  flex: 1;
  background: #fff;
  box-shadow: 0px 6px 26px 0px rgba(36, 80, 154, 0.15);
  border-radius: 20px;
  display: flex;
  flex-direction: column;

  position: relative;

  .header {
    position: absolute;
    z-index: 999;
    top: 40px;
    left: 40px;
    display: flex;
    align-items: center;
    .icon {
      font-size: 16px;
      font-weight: bold;
      margin-right: 4px;
    }
    .title {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .left-panel {
    position: absolute;
    top: 80px;
    left: 40px;
    padding: 24px;
    background: #fff;
    box-shadow: 0px 0px 13px 0px rgba(82, 131, 221, 0.1);
    border-radius: 6px;
    border: 2px solid #eff1f6;

    .title {
      display: flex;
      align-items: center;
      color: #0562fd;
      font-size: 18px;
      font-weight: 600;
      line-height: 1em;
      margin-bottom: 8px;

      .icon {
        margin-top: 1px;
        height: 0.8em;
        width: 4px;
        margin-right: 8px;
        background-color: #0562fd;
      }
    }

    .list {
      height: 30vh;
      overflow-y: auto;
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      min-width: 240px;
      color: rgba(96, 112, 137, 0.69);
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(207, 220, 238, 0.71);

      .icon {
        color: #abb3c0;
        font-size: 12px;
      }
    }
    .item:last-child {
      padding-bottom: 0;
      border-bottom-width: 0;
    }
  }
}
</style>