<template>
  <div class="wrapper">
    <div id="model"></div>

    <div class="right-panel">
      <img src="@/assets/icons/max.png" alt @click="max" />
      <img src="@/assets/icons/min.png" alt @click="min" />
      <!-- <img src="@/assets/icons/transform.png" alt /> -->
    </div>

    <div class="bottom-panel">
      <div @click="setCenter">居中</div>
      <div @click="toggleAxesHelper">坐标轴</div>
      <div @click="rotateX">X轴旋转</div>
      <div @click="rotateY">Y轴旋转</div>
      <div @click="rotateZ">Z轴旋转</div>
      <div @click="snapshot">截屏</div>
      <div @click="print">打印</div>
      <div @click="downloadModelData">导出数据</div>
    </div>

    <!-- <div class="tools">
      <div class="item" @click="refreshModel">重置</div>
      <div class="item" @click="setCenter">居中</div>

      <input type="color" id="color" v-model="color" style="display: none" />
      <label class="item" for="color">颜色</label>

      <div class="item" @click="toggleAxesHelper">坐标轴</div>

      <div class="item" @click="max">放大</div>
      <div class="item" @click="min">缩小</div>
      <div class="item" @click="rotateX">X轴旋转</div>
      <div class="item" @click="rotateY">Y轴旋转</div>
      <div class="item" @click="rotateZ">Z轴旋转</div>

      <div class="item" @click="cameraVisible = !cameraVisible">调整视角</div>

      <div class="item" @click="cutVisible = !cutVisible">剖面</div>

      <div class="item" @click="snapshot">网页快照</div>
      <div class="item" @click="print">打印</div>
      <div class="item" @click="downloadModelData">导出数据</div>
    </div>

    <div
      class="right-panel"
      :style="{
        right: selectedModelIndex !== -1 ? '0' : '-400px',
      }"
    >
      <div class="camera">
        <div class="title">
          <span>模块基本信息</span>
          <span class="close" @click="cancelSelect">x</span>
        </div>
        <div style="margin-bottom: 12px">{{ selectedSubModel.name ? selectedSubModel.name : "--" }}</div>
        <div class="title">颜色</div>
        <div class="value">
          <input type="color" v-model="modelColor" />
        </div>
        <div class="title">删除</div>
        <button @click="deleteModel">删除</button>
      </div>
    </div>

    <div class="right-panel" :style="{
        right: cameraVisible ? '0' : '-400px',
      }">
      <div class="camera">
        <div class="title">视角角度</div>
        <div class="value">
          <div>
            X轴：
            <input v-model="cameraPositionX" type="number" />
          </div>
          <div>
            Y轴：
            <input v-model="cameraPositionY" type="number" />
          </div>
          <div>
            Z轴：
            <input v-model="cameraPositionZ" type="number" />
          </div>
        </div>
        <div class="title">快捷操作</div>
        <div class="button-group">
          <div class="button" @click="seeUp">上</div>
          <div class="button" @click="seeDown">下</div>
          <div class="button" @click="seeLeft">左</div>
          <div class="button" @click="seeRight">右</div>
          <div class="button" @click="seeFront">前</div>
          <div class="button" @click="settBack">后</div>
        </div>
      </div>
    </div>

    <div class="right-panel" :style="{
        right: cutVisible ? '0' : '-400px',
      }">
      <div class="camera">
        <div class="title">剖面位置</div>
        <div class="button-group">
          <button class @click="cutX">X轴</button>
          <button class @click="cutY">Y轴</button>
          <button class @click="cutZ">Z轴</button>
        </div>
        <div class="title">剖面坐标</div>
        <div v-if="isCutting === 'x'">
          X轴偏移
          <input
            type="range"
            :min="-xRange"
            :max="xRange"
            v-model="cutPositionX"
            style="width: 240px"
          />
        </div>
        <div v-if="isCutting === 'y'">
          Y轴偏移
          <input
            type="range"
            :min="-yRange"
            :max="yRange"
            v-model="cutPositionY"
            style="width: 240px"
          />
        </div>
        <div v-if="isCutting === 'z'">
          Z轴偏移
          <input
            type="range"
            :min="-zRange"
            :max="zRange"
            v-model="cutPositionZ"
            style="width: 240px"
          />
        </div>
        <div class="title">视角反转</div>
        <div>
          <button @click="isCutLeftArea = !isCutLeftArea">切换</button>
        </div>
      </div>
    </div>-->
  </div>
</template>


<script>
import * as THREE from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { saveAs } from "file-saver";

// let scene, camera, renderer, model, axisHelper;

// const gltfLoader = new GLTFLoader();
// const fbxLoader = new FBXLoader();

// let helpers;

// function initScene() {
//   scene = new THREE.Scene();
//   scene.background = new THREE.Color(0xf5f5f5);

//   // camera = new THREE.PerspectiveCamera(
//   //   75,
//   //   window.innerWidth / window.innerHeight,
//   //   0.1,
//   //   1000
//   // );

//   camera = new THREE.OrthographicCamera(
//     window.innerWidth / -14.5,
//     window.innerWidth / 14.5,
//     window.innerHeight / 14.5,
//     window.innerHeight / -14.5,
//     -1000,
//     6000
//   );

//   camera.position.set(2000, 2000, 2000);

//   const ambient = new THREE.AmbientLight(0xffffff, 0.5);
//   scene.add(ambient);

//   renderer = new THREE.WebGLRenderer({
//     antialias: true,
//     preserveDrawingBuffer: true
//   });
//   renderer.setPixelRatio(window.devicePixelRatio);
//   renderer.setSize(window.innerWidth, window.innerHeight);
//   renderer.localClippingEnabled = true;
//   document.body.appendChild(renderer.domElement);

//   const light3 = new THREE.DirectionalLight(0xffffff, 0.8);
//   light3.position.set(10000, 10000, 10000);
//   scene.add(light3);

//   const light4 = new THREE.DirectionalLight(0xffffff, 0.8);
//   light4.position.set(-10000, -10000, 10000);
//   scene.add(light4);

//   const controls = new OrbitControls(camera, renderer.domElement); //创建控件对象
//   controls.addEventListener("change", render); //监听鼠标、键盘事件

//   // setAxesHelper();

//   // const planeList = [new THREE.Plane(new THREE.Vector3(1, 0, 0), 20)];
//   // renderer.clippingPlanes = planeList;
//   // renderer.localClippingEnabled = true;
//   // var helper = new THREE.PlaneHelper(planeList[0], 300, 0xffff00);
//   // scene.add(helper);

//   render();
// }

// initScene();

// function render() {
//   console.log("render");
//   renderer.render(scene, camera); //执行渲染操作
// }

// function setAxesHelper() {

// }

export default {
  props: ["url"],

  data() {
    return {
      color: "",

      cameraPositionX: 400,
      cameraPositionY: 400,
      cameraPositionZ: 400,

      cameraVisible: false,
      selectedModelIndex: -1, // 用户选择的子模型
      modelColor: "",

      cutPositionX: 0,
      cutPositionY: 0,
      cutPositionZ: 0,
      cutVisible: false,

      xRange: 0,
      yRange: 0,
      zRange: 0,

      isCutLeftArea: false, // 反转视角
      isCutting: "",

      deletedModelList: [],
    };
  },

  watch: {
    url() {
      if (this.url) {
        this.renderByURL(this.url);
      }
    },
  },

  // watch: {
  //   // color() {
  //   //   if (model && Array.isArray(model.children)) {
  //   //     console.log(this.color);
  //   //     model.children.forEach((item) => {
  //   //       if (item.material && item.material.color) {
  //   //         item.material.color = new THREE.Color(
  //   //           parseInt("0x" + this.color.substring(1), 16)
  //   //         );
  //   //       }
  //   //     });
  //   //     render();
  //   //   }
  //   // },

  //   // modelColor() {
  //   //   console.log(this.modelColor, model.children, this.selectedModelIndex);
  //   //   if (
  //   //     this.modelColor &&
  //   //     model &&
  //   //     model.children &&
  //   //     model.children[this.selectedModelIndex]
  //   //   ) {
  //   //     model.children[this.selectedModelIndex].material.color =
  //   //       new THREE.Color(parseInt("0x" + this.modelColor.substring(1), 16));

  //   //     render();
  //   //   }
  //   // },

  //   cameraPositionX() {
  //     this.setCameraPosition();
  //   },
  //   cameraPositionY() {
  //     this.setCameraPosition();
  //   },
  //   cameraPositionZ() {
  //     this.setCameraPosition();
  //   },

  //   // 监听用户滑动X轴剖面偏移
  //   cutPositionX() {
  //     this.cutX();
  //   },
  //   cutPositionY() {
  //     this.cutY();
  //   },
  //   cutPositionZ() {
  //     this.cutZ();
  //   },
  //   isCutLeftArea() {
  //     if (this.isCutting === "x") {
  //       this.cutPositionX = 0;
  //       this.cutX();
  //     }
  //     if (this.isCutting === "y") {
  //       this.cutPositionY = 0;
  //       this.cutY();
  //     }
  //     if (this.isCutting === "z") {
  //       this.cutPositionZ = 0;
  //       this.cutZ();
  //     }
  //   }
  // },

  // computed: {
  //   selectedSubModel() {
  //     console.log("selectedModelIndex", this.selectedModelIndex);
  //     if (model && model.children && model.children[this.selectedModelIndex]) {
  //       return model.children[this.selectedModelIndex];
  //     } else {
  //       return {};
  //     }
  //   }
  // },

  mounted() {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff);

    const camera = new THREE.OrthographicCamera(
      window.innerWidth / -14.5,
      window.innerWidth / 14.5,
      window.innerHeight / 14.5,
      window.innerHeight / -14.5,
      -1000,
      6000
    );

    camera.position.set(2000, 2000, 2000);

    const ambient = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambient);

    const dom = document.querySelector("#model");
    const { width, height } = dom.getBoundingClientRect();

    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: true,
    });

    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);
    renderer.localClippingEnabled = true;

    dom.appendChild(renderer.domElement);

    const light3 = new THREE.DirectionalLight(0xffffff, 0.8);
    light3.position.set(10000, 10000, 10000);
    scene.add(light3);

    const light4 = new THREE.DirectionalLight(0xffffff, 0.8);
    light4.position.set(-10000, -10000, 10000);
    scene.add(light4);

    const controls = new OrbitControls(camera, renderer.domElement); //创建控件对象
    controls.addEventListener("change", this.render); //监听鼠标、键盘事件

    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;
    this.controls = controls;

    this.gltfLoader = new GLTFLoader();
    this.fbxLoader = new FBXLoader();

    // setAxesHelper();

    // const planeList = [new THREE.Plane(new THREE.Vector3(1, 0, 0), 20)];
    // renderer.clippingPlanes = planeList;
    // renderer.localClippingEnabled = true;
    // var helper = new THREE.PlaneHelper(planeList[0], 300, 0xffff00);
    // scene.add(helper);

    this.render();

    // this.renderByURL("/1141065324105371648_CNC_A.gltf");

    // renderer.domElement.addEventListener("click", this.choose); // 监听窗口鼠标单击事件

    // const arr = location.search.substring(1).split("=");
    // if (arr[1]) {
    //   const list = arr[1].split(",");
    //   this.fileList = list.filter(item => item.indexOf(".pdf") > -1);

    //   const file = list.find(item => item.indexOf(".gltf") > -1);
    //   if (file) {
    //     this.renderByURL(decodeURIComponent(file));
    //   } else {
    //     const file2 = list.find(item => item.indexOf(".fbx") > -1);
    //     if (file2) {
    //       this.renderByURL(decodeURIComponent(file2));
    //     }
    //   }
    // }
  },

  methods: {
    render() {
      console.log("render");
      this.renderer.render(this.scene, this.camera); //执行渲染操作
    },

    renderByURL(url) {
      console.log("url", url);

      if (this.model) {
        this.scene.remove(this.model);
      }
      const that = this;

      if (url.indexOf(".gltf") > -1) {
        const hide = this.$message.loading("渲染中...", 0);

        this.gltfLoader.load(url, function (gltf) {
          console.log("gltf", gltf);
          that.model = gltf.scene.children[0];
          console.log("model", that.model);
          that.model.rotateX(THREE.MathUtils.degToRad(-90));
          that.scene.add(that.model);
          that.model.scale.set(0.001, 0.001, 0.001);
          that.setCenter();
          hide();
        });
      }
      // else if (url.indexOf(".fbx") > -1) {
      //   const hide = this.$message.loading("渲染中...", 0);

      //   fbxLoader.load(url, function(obj) {
      //     console.log("fbx", obj);
      //     that.model = obj;

      //     let name = "";
      //     obj.children.forEach(item => {
      //       name = name + "," + item.name;
      //       if (item.material && item.material.color) {
      //         item.material.color = new THREE.Color(0x404040);
      //       }
      //     });
      //     console.log(name);

      //     model.rotateX(THREE.MathUtils.degToRad(-90));

      //     scene.add(model);
      //     that.setCenter();
      //     // render();

      //     hide();
      //   });
      // }
      else {
        this.$message.error("不支持这种格式");
      }
    },

    setCenter() {
      const { model } = this;
      console.log("set center");
      if (model) {
        // object.updateMatrixWorld();
        // 获得包围盒得min和max
        const box = new THREE.Box3().setFromObject(model);
        // console.log("box size", box.getSize());
        // 返回包围盒的中心点
        const size = box.getSize(new THREE.Vector3());
        console.log("size", size);

        this.xRange = Math.round(size.x);
        this.yRange = Math.round(size.y);
        this.zRange = Math.round(size.z);

        const center = box.getCenter(new THREE.Vector3());
        model.position.x += model.position.x - center.x;
        model.position.y += model.position.y - center.y;
        model.position.z += model.position.z - center.z;
        console.log("set center done ");
        this.render();
      }
    },

    // onFileChange(e) {
    //   console.log("e", e);
    //   const files = e.target.files;
    //   if (!files[0]) return;
    //   const url = URL.createObjectURL(files[0]);
    //   this.renderByURL(url, files[0].name);
    // },

    //     renderByInput() {
    //   if (this.inputURL) {
    //     this.renderByURL(this.inputURL);
    //   } else {
    //     alert("请输入文件链接");
    //   }
    // },

    // setCameraPosition() {
    //   const cameraPositionX = parseInt(this.cameraPositionX);
    //   const cameraPositionY = parseInt(this.cameraPositionY);
    //   const cameraPositionZ = parseInt(this.cameraPositionZ);
    //   if (
    //     !isNaN(cameraPositionX) &&
    //     !isNaN(cameraPositionY) &&
    //     !isNaN(cameraPositionZ)
    //   ) {
    //     console.log(
    //       "set camera position",
    //       cameraPositionX,
    //       cameraPositionY,
    //       cameraPositionZ
    //     );
    //     camera.position.set(cameraPositionX, cameraPositionY, cameraPositionZ);
    //     camera.lookAt(scene.position); //设置相机方向(指向的场景对象)
    //     render();
    //   } else {
    //     console.log("camera position error");
    //   }
    // },

    // choose(event) {
    //   var Sx = event.clientX; //鼠标单击位置横坐标
    //   var Sy = event.clientY; //鼠标单击位置纵坐标
    //   //屏幕坐标转WebGL标准设备坐标
    //   var x = (Sx / window.innerWidth) * 2 - 1; //WebGL标准设备横坐标
    //   var y = -(Sy / window.innerHeight) * 2 + 1; //WebGL标准设备纵坐标
    //   //创建一个射线投射器`Raycaster`
    //   var raycaster = new THREE.Raycaster();
    //   //通过鼠标单击位置标准设备坐标和相机参数计算射线投射器`Raycaster`的射线属性.ray
    //   raycaster.setFromCamera(new THREE.Vector2(x, y), camera);
    //   //返回.intersectObjects()参数中射线选中的网格模型对象
    //   // 未选中对象返回空数组[],选中一个数组1个元素，选中两个数组两个元素
    //   if (model && model.children) {
    //     var intersects = raycaster.intersectObjects(model.children);
    //     console.log("射线投射器返回的对象", intersects);
    //     // console.log("射线投射器返回的对象 点point", intersects[0].point);
    //     // console.log("射线投射器返回的对象 几何体",intersects[0].object.geometry.vertices)
    //     // intersects.length大于0说明，说明选中了模型
    //     if (intersects.length > 0) {
    //       // 选中模型的第一个设置为半透明
    //       const element = intersects[0].object;
    //       if (element) {
    //         let findIndex = -1;
    //         model.children.forEach((item, index) => {
    //           if (item.material && item.id === element.id) {
    //             console.log("item.material.color", item.material.color);
    //             console.log("item.material.color2", item.material.color2);

    //             if (!item.material.color2) {
    //               item.material.color2 = item.material.color;
    //             }

    //             item.material.color = new THREE.Color(0xff0000);

    //             // item.material.transparent = true;
    //             // item.material.opacity = 0.1;
    //             findIndex = index;
    //           }
    //         });
    //         console.log("findindex", findIndex);
    //         if (this.selectedModelIndex !== -1) {
    //           model.children[this.selectedModelIndex].material.color =
    //             model.children[this.selectedModelIndex].material.color2;
    //         }
    //         this.selectedModelIndex = findIndex;
    //         // model.children.splice(findIndex, 1);

    //         render();
    //       }
    //     }
    //   }
    // },

    // refreshModel() {
    //   if (this.deletedModelList.length > 0) {
    //     if (model) {
    //       model.children.push(
    //         ...this.deletedModelList.map(item => {
    //           item.material.color = item.material.color2;
    //           return item;
    //         })
    //       );

    //       render();
    //     }
    //   }
    // },

    // cancelSelect() {
    //   if (model && model.children && model.children[this.selectedModelIndex]) {
    //     model.children[
    //       this.selectedModelIndex
    //     ].material.color = new THREE.Color(0x404040);
    //     this.selectedModelIndex = -1;
    //     render();
    //   }
    // },

    // cutX() {
    //   const clipPlanes = [
    //     new THREE.Plane(
    //       new THREE.Vector3(this.isCutLeftArea ? -1 : 1, 0, 0),
    //       parseInt(this.cutPositionX)
    //     )
    //   ];
    //   if (model && model.children) {
    //     model.children.forEach(item => {
    //       if (item.material) {
    //         item.material.side = THREE.DoubleSide;
    //         item.material.clippingPlanes = clipPlanes;
    //         item.material.clipIntersection = true;
    //       }
    //     });
    //   }

    //   scene.remove(helpers);

    //   helpers = new THREE.Group();
    //   helpers.add(new THREE.PlaneHelper(clipPlanes[0], 100, 0xff0000));
    //   helpers.visible = true;
    //   scene.add(helpers);
    //   this.isCutting = "x";

    //   render();
    // },
    // cutY() {
    //   const clipPlanes = [
    //     new THREE.Plane(
    //       new THREE.Vector3(0, this.isCutLeftArea ? -1 : 1, 0),
    //       parseInt(this.cutPositionY)
    //     )
    //   ];
    //   if (model && model.children) {
    //     model.children.forEach(item => {
    //       if (item.material) {
    //         item.material.side = THREE.DoubleSide;
    //         item.material.clippingPlanes = clipPlanes;
    //         item.material.clipIntersection = true;
    //       }
    //     });
    //   }

    //   scene.remove(helpers);

    //   helpers = new THREE.Group();
    //   helpers.add(new THREE.PlaneHelper(clipPlanes[0], 100, 0xff0000));
    //   helpers.visible = true;
    //   scene.add(helpers);
    //   this.isCutting = "y";

    //   render();
    // },
    // cutZ() {
    //   const clipPlanes = [
    //     new THREE.Plane(
    //       new THREE.Vector3(0, 0, this.isCutLeftArea ? -1 : 1),
    //       parseInt(this.cutPositionZ)
    //     )
    //   ];
    //   if (model && model.children) {
    //     model.children.forEach(item => {
    //       if (item.material) {
    //         item.material.side = THREE.DoubleSide;
    //         item.material.clippingPlanes = clipPlanes;
    //         item.material.clipIntersection = true;
    //       }
    //     });
    //   }

    //   scene.remove(helpers);

    //   helpers = new THREE.Group();
    //   helpers.add(new THREE.PlaneHelper(clipPlanes[0], 100, 0xff0000));
    //   helpers.visible = true;
    //   scene.add(helpers);
    //   this.isCutting = "z";
    //   render();
    // },

    min() {
      const { model } = this;
      if (model) {
        const { x, y, z } = model.scale;
        model.scale.set(x - 0.001, y - 0.001, z - 0.001);
        this.render();
      }
    },
    max() {
      const { model } = this;
      if (model) {
        const { x, y, z } = model.scale;
        model.scale.set(x + 0.001, y + 0.001, z + 0.001);
        this.render();
      }
    },
    rotateX() {
      const { model } = this;
      if (model) {
        model.rotation.x += 0.2;
        this.render();
      }
    },
    rotateY() {
      const { model } = this;
      if (model) {
        model.rotation.y += 0.2;
        this.render();
      }
    },
    rotateZ() {
      const { model } = this;
      if (model) {
        model.rotation.z += 0.2;
        this.render();
      }
    },

    toggleAxesHelper() {
      if (!this.axisHelper) {
        const axisHelper = new THREE.AxesHelper(600);
        this.scene.add(axisHelper);
        this.axisHelper = axisHelper;
      } else {
        this.scene.remove(this.axisHelper);
        this.axisHelper = null;
      }
      this.render();
    },

    // seeUp() {
    //   camera.position.set(0, 200, 0);
    //   camera.lookAt(scene.position); //设置相机方向(指向的场景对象)
    //   render();
    // },
    // seeDown() {
    //   camera.position.set(0, -200, 0);
    //   camera.lookAt(scene.position); //设置相机方向(指向的场景对象)

    //   render();
    // },
    // seeLeft() {
    //   camera.position.set(-200, 0, 0);
    //   camera.lookAt(scene.position); //设置相机方向(指向的场景对象)

    //   render();
    // },
    // seeRight() {
    //   camera.position.set(200, 0, 0);
    //   camera.lookAt(scene.position); //设置相机方向(指向的场景对象)

    //   render();
    // },
    // seeFront() {
    //   camera.position.set(0, 0, 200);
    //   camera.lookAt(scene.position); //设置相机方向(指向的场景对象)

    //   render();
    // },
    // settBack() {
    //   camera.position.set(0, 0, -200);
    //   camera.lookAt(scene.position); //设置相机方向(指向的场景对象)

    //   render();
    // },

    // deleteModel() {
    //   if (model && model.children && model.children[this.selectedModelIndex]) {
    //     const arr = model.children.splice(this.selectedModelIndex, 1);
    //     this.deletedModelList.push(...arr);
    //     this.selectedModelIndex = -1;
    //     render();
    //   }
    // },

    print() {
      const canvas = document.getElementsByTagName("canvas")[0];

      console.log("canvas", canvas);
      if (!canvas) return;

      const imagePath = canvas.toDataURL("image/png");
      const img = `<img src="${imagePath}" />`;

      // console.log("img path", imagePath);

      var iframe = window.document.createElement("iframe");
      iframe.setAttribute(
        "style",
        "position:absolute;width:0px;height:0px;left:-5000px;top:-5000px;"
      );
      window.document.body.appendChild(iframe);
      var doc = iframe.contentWindow.document;
      doc.write(img);

      var ys = `body{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        img {
          width: 100%;
        }
       `;
      var style = window.document.createElement("style");
      style.innerText = ys;
      doc.getElementsByTagName("head")[0].appendChild(style);

      setTimeout(() => {
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
      }, 0);
    },

    snapshot() {
      const canvas = document.getElementsByTagName("canvas")[0];

      console.log("canvas", canvas);
      if (!canvas) return;
      canvas.toBlob(function (blob) {
        console.log("blob", blob);
        saveAs(blob, `model.png`);
      });
    },

    downloadModelData() {
      const { model } = this;
      if (model) {
        const blob = new Blob([JSON.stringify(model)], {
          type: "application/json;charset=UTF-8",
        });
        saveAs(blob, `model.json`);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  #model {
    flex: 1;
  }

  .right-panel {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
    display: flex;
    flex-direction: column;
    gap: 4em;
    img {
      height: 34px;
      cursor: pointer;
    }
  }

  .bottom-panel {
    position: absolute;
    bottom: 40px;
    left: 40px;
    right: 40px;
    height: 49px;
    background: rgba(235, 242, 252, 0.7);
    border-radius: 25px;
    gap: 70px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    font-weight: 500;
    color: #91a0b8;
    line-height: 22px;

    & > div {
      cursor: pointer;
    }
  }
}
</style>